import { render, staticRenderFns } from "./SessionCmsBoardWidget.vue?vue&type=template&id=0471cc98&scoped=true&"
import script from "./SessionCmsBoardWidget.vue?vue&type=script&lang=ts&"
export * from "./SessionCmsBoardWidget.vue?vue&type=script&lang=ts&"
import style0 from "../../assets/styles/speaker-cms/SpeakerCmsSessionPanelWidget.scss?vue&type=style&index=0&id=0471cc98&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0471cc98",
  null
  
)

export default component.exports